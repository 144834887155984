// import { useEffect, useState } from "react";

const Footer=(props)=>{
// const [show,setShow]=useState(true);
// useEffect(()=>{
//     const a=setTimeout(()=>setShow(false),20000);
//     // return clearTimeout(a);
// },[]);
// const classes=`${!show&&"displayNone"} ${"footer"}`
return <>
<div className="footer">
    Developed With <span>♥</span>  By Ahmed Khalifa & Ali Mega;
</div>
</>
}
export default Footer;